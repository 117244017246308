/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import noticeError from 'utils/logger';
import alertMessage from 'global/AlertMessage';
import JSZip from 'jszip';
import produce from 'immer';
import {
  ChartData,
  CreateStudyOptions,
  IChartWidgetApi,
  IChartingLibraryWidget,
  LineToolState,
  LineToolsGroupState,
  ResolutionString,
  StudyInputValue,
  ThemeName,
} from '../../../../public/tradingview/charting_library';
import templates from '../config/template';
import { IndicatorDataType } from '../indicators/constants/types';
import { DesktopOptions, MobileProps, TemplateModel } from '../types';
import getNewTemplate from '../config/newTemplate';
import { useChartbitStore, UserSetting } from '../chartbit.store';

const lightTemplate = templates[0].template;
const darkTemplate = templates[1].template;

export const getClientTheme = (tvWidgetKey?: string): 'light' | 'dark' => {
  try {
    if (window && window.tvWidget && window.tvWidget[tvWidgetKey]) {
      return window.tvWidget[tvWidgetKey].getTheme();
    }

    return (
      (window.localStorage.getItem('stradingview.current_theme.name') as
        | 'light'
        | 'dark') || 'light'
    );
  } catch (error) {
    noticeError(error, { event: 'getClientTheme', place: 'TradingView/utils' });
    return 'light';
  }
};

export const isMainSeriesExist = (layout) =>
  layout.charts[0].panes.some(({ sources }) =>
    sources.some(({ type }) => type && type.toUpperCase() === 'MAINSERIES'),
  );

export const injectSymbolToMainSeries = (source, symbol: string) => {
  const newSource = { ...source };
  if (
    newSource.type &&
    newSource.type.toUpperCase() === 'MAINSERIES' &&
    newSource.state
  ) {
    newSource.state.symbol = symbol.toUpperCase();
    newSource.state.shortName = symbol.toUpperCase();
  }

  return newSource;
};

export const fixHiddenExchangeAndInterval = (source) => {
  const newSource = { ...source };
  if (
    newSource.type &&
    newSource.type.toUpperCase() === 'MAINSERIES' &&
    newSource.state
  ) {
    // We need to set all saved layout in Database showExhance, showInterval to true
    // Hiding Exchange and Interval for mobile now is controlled by CSS in custom-mobile.css
    newSource.state.statusViewStyle.showExchange = true;
    newSource.state.statusViewStyle.showInterval = true;
  }

  return newSource;
};

export const resolveOldStudy = (source) => {
  const newSource = { ...source };
  if (
    newSource.type &&
    newSource.type.toUpperCase() === 'STUDY' &&
    newSource.state
  ) {
    // `source.state.inputs.in_1` will be used to identify is the old data already resolved
    // other data still being keep for unexpected issues in the future
    switch (source.state?.shortId?.toUpperCase()) {
      case 'MOVING AVERAGE':
      case 'MOVING AVERAGE EXPONENTIAL':
      case 'MOVING AVERAGE WEIGHTED': {
        if (source.state.inputs && source.state.inputs.in_1 !== undefined) {
          newSource.state.inputs.symbol = '';
          newSource.state.inputs.length = source.state.inputs.in_0;
          newSource.state.inputs.source = source.state.inputs.in_1;
          newSource.state.inputs.offset = source.state.inputs.in_2;

          delete newSource.state.inputs.in_1;
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  return newSource;
};

export const validateLayout = (layout: any): TemplateModel => {
  const themeFromStorage = getClientTheme();
  const fallbackTemplate =
    themeFromStorage.toLowerCase() === 'dark' ? darkTemplate : lightTemplate;

  // probably API load layout error
  if (!layout) return fallbackTemplate;

  // `layout.layout` is used to differentiate Old and New layout schema
  if (!layout.layout) {
    // Old Layout Schema (probably generated when accessing old tradingview versions).
    // Migrate old layout to new layout schema
    const newLayout = { ...fallbackTemplate };
    if (layout.panes) {
      newLayout.charts[0].panes = layout.panes;
    }
    if (layout.theme) {
      newLayout.charts[0].theme = layout.theme;
    }
    if (layout.chartProperties) {
      newLayout.charts[0].chartProperties = layout.chartProperties;
    }
    return newLayout as TemplateModel;
  }

  if (!isMainSeriesExist(layout)) return fallbackTemplate;

  return layout as TemplateModel;
};

export const changeTheme = async (tvWidgetKey: string, theme: ThemeName) => {
  if (!window.tvWidget) return false;
  return window.tvWidget[tvWidgetKey].changeTheme(theme);
};

export const overrideCandleColor = (
  tvWidget: IChartingLibraryWidget,
  theme: ThemeName,
) => {
  if (!tvWidget) return;
  switch (theme) {
    case 'light':
      tvWidget.applyOverrides({
        'mainSeriesProperties.candleStyle.upColor': '#009900',
        'mainSeriesProperties.candleStyle.downColor': '#B80E0C',
        'mainSeriesProperties.candleStyle.wickUpColor': '#000000',
        'mainSeriesProperties.candleStyle.wickDownColor': '#000000',
        'mainSeriesProperties.candleStyle.borderColor': '#000000',
        'mainSeriesProperties.candleStyle.borderUpColor': '#000000',
        'mainSeriesProperties.candleStyle.borderDownColor': '#000000',
        'mainSeriesProperties.statusViewStyle.showExchange': true,
        'mainSeriesProperties.statusViewStyle.showInterval': true,
      });
      break;
    case 'dark':
      tvWidget.applyOverrides({
        'mainSeriesProperties.candleStyle.upColor': '#53b987',
        'mainSeriesProperties.candleStyle.downColor': '#eb4d5c',
        'mainSeriesProperties.candleStyle.wickUpColor': '#336854',
        'mainSeriesProperties.candleStyle.wickDownColor': '#7f323f',
        'mainSeriesProperties.candleStyle.borderColor': '#378658',
        'mainSeriesProperties.candleStyle.borderUpColor': '#53b987',
        'mainSeriesProperties.candleStyle.borderDownColor': '#eb4d5c',
        'mainSeriesProperties.statusViewStyle.showExchange': true,
        'mainSeriesProperties.statusViewStyle.showInterval': true,
      });
      break;
    default:
      break;
  }
};

// localize other platform after being forced to change to desktop colors
export const localizedColors = (chartLayout: any) => {
  const overrideLayout = { ...chartLayout };
  const toOverride = [];

  if (chartLayout) {
    overrideLayout.layout.charts.forEach((x) => {
      const temp = { ...x };

      if (overrideLayout.theme === 'dark') {
        if (
          x.chartProperties.paneProperties.background.toLowerCase() ===
            '#fff' ||
          x.chartProperties.paneProperties.background.toLowerCase() ===
            '#ffffff' ||
          x.chartProperties.paneProperties.background.toLowerCase() ===
            '#131722' ||
          (x.chartProperties.paneProperties.background.toLowerCase() ===
            '#1f1f1f' &&
            x.chartProperties.paneProperties.backgroundType === 'gradient' &&
            x.chartProperties.paneProperties.backgroundEndColor === '#131722')
        ) {
          temp.chartProperties.paneProperties.background = '#1f1f1f';
          temp.chartProperties.paneProperties.backgroundType = 'solid';
          temp.chartProperties.paneProperties.vertGridProperties.color =
            'rgba(196, 196, 196, 0.06)';
          temp.chartProperties.paneProperties.horzGridProperties.color =
            'rgba(196, 196, 196, 0.06)';
          if (x.chartProperties.scalesProperties.textColor === '#131722') {
            temp.chartProperties.scalesProperties.textColor = '#B2B5BE';
          }
          if (x.chartProperties.paneProperties.separatorColor === '#2A2E39') {
            temp.chartProperties.paneProperties.separatorColor = '#E0E3EB';
          }
        }
      }
      if (
        (overrideLayout.theme === 'light' &&
          x.chartProperties.paneProperties.background.toLowerCase() ===
            '#1f1f1f') ||
        x.chartProperties.paneProperties.background.toLowerCase() === '#131722'
      ) {
        temp.chartProperties.paneProperties.backgroundType = 'solid';
        temp.chartProperties.paneProperties.background = '#FFF';
        temp.chartProperties.paneProperties.vertGridProperties.color =
          'rgba(225, 236, 242, 0.27)';
        temp.chartProperties.paneProperties.horzGridProperties.color =
          'rgba(225, 236, 242, 0.27)';
      }
      if (
        x.chartProperties.scalesProperties.textColor === '#B2B5BE' &&
        overrideLayout.theme === 'light'
      ) {
        temp.chartProperties.scalesProperties.textColor = '#131722';
      }
      if (x.chartProperties.paneProperties.separatorColor === '#E0E3EB') {
        temp.chartProperties.paneProperties.separatorColor = '#2A2E39';
      }
      toOverride.push(temp);
    });
    overrideLayout.layout.charts = toOverride;
  }

  return overrideLayout;
};

export const overrideByTheme = (theme: 'light' | 'dark', chartLayout: any) => {
  if (chartLayout) {
    chartLayout.theme = theme;
    chartLayout.layout.charts[0].chartProperties.scalesProperties.textColor =
      theme === 'dark' ? '#B2B5BE' : '#555';
    chartLayout.layout.charts[0].chartProperties.paneProperties.backgroundType =
      theme === 'dark' ? 'gradient' : 'solid';
    chartLayout.layout.charts[0].chartProperties.paneProperties.background =
      theme === 'dark' ? '#131722' : '#ffffff';
    if (theme === 'dark') {
      chartLayout.layout.charts[0].chartProperties.paneProperties.backgroundGradientStartColor =
        '#181C27';
      chartLayout.layout.charts[0].chartProperties.paneProperties.backgroundGradientEndColor =
        '#131722';
    }

    chartLayout.layout.charts[0].panes.forEach(({ sources }) => {
      const mainSeries = sources.find(
        ({ type }) => type && type.toUpperCase() === 'MAINSERIES',
      );
      mainSeries.state.candleStyle.upColor =
        theme === 'dark' ? '#53b987' : '#009900';
      mainSeries.state.candleStyle.downColor =
        theme === 'dark' ? '#eb4d5c' : '#B80E0C';
      mainSeries.state.candleStyle.wickUpColor =
        theme === 'dark' ? '#336854' : '#000000';
      mainSeries.state.candleStyle.wickDownColor =
        theme === 'dark' ? '#7f323f' : '#000000';
      mainSeries.state.candleStyle.borderColor =
        theme === 'dark' ? '#378658' : '#000000';
      mainSeries.state.candleStyle.borderUpColor =
        theme === 'dark' ? '#53b987' : '#000000';
      mainSeries.state.candleStyle.borderDownColor =
        theme === 'dark' ? '#eb4d5c' : '#000000';
    });
  }

  return chartLayout;
};

export const prepareLayoutBeforeLoad = (
  rawLayout: TemplateModel | null,
  symbol: string,
  skipOverwriteAxises = false,
) => {
  const layout = validateLayout(rawLayout);
  const themeFromServer = layout.charts[0].theme as 'light' | 'dark';
  layout.charts[0].panes = layout.charts[0].panes.map(
    ({
      sources,
      /** TV v22 down */
      rightAxisState,
      /** TV v22 up */
      rightAxisesState,
      ...rest
    }) => {
      const newPanes: Record<string, any> = {
        ...rest,
        sources: sources.map((source) => {
          let newSource = { ...source };
          newSource = injectSymbolToMainSeries(newSource, symbol);
          newSource = fixHiddenExchangeAndInterval(newSource);
          newSource = resolveOldStudy(newSource);

          return newSource;
        }),
      };

      if (rightAxisState) {
        if (!skipOverwriteAxises) rightAxisState.m_priceRange = null;

        newPanes.rightAxisState = rightAxisState;
      }
      if (rightAxisesState) {
        if (
          !skipOverwriteAxises &&
          rightAxisesState?.[0]?.state?.m_priceRange
        ) {
          rightAxisesState[0].state.m_priceRange = null;
        }

        newPanes.rightAxisesState = rightAxisesState;
      }

      return newPanes;
    },
  );
  return { layout, theme: themeFromServer };
};

export const injectThemeToSavedLayout = (
  layout,
  theme,
  isDesktop?: DesktopOptions | boolean,
) => {
  const newLayout = { ...layout };
  try {
    if (!theme || !layout.charts || !layout.charts[0]) return layout;
    newLayout.charts[0].theme = theme;
    if (isDesktop) {
      newLayout.charts[0].theme = 'dark';
      newLayout.charts.forEach((x, index) => {
        x.theme = 'dark';
        if (x.chartProperties.paneProperties.background === '#ffffff') {
          x.chartProperties.paneProperties.background = '#1F1F1F';
          x.chartProperties.scalesProperties.textColor = '#B2B5BE';
          x.chartProperties.paneProperties.separatorColor = '#2A2E39';
          x.chartProperties.paneProperties.separatorColor = '#2A2E39';
          x.chartProperties.paneProperties.vertGridProperties.color =
            'rgba(196, 196, 196, 0.06)';
          x.chartProperties.paneProperties.horzGridProperties.color =
            'rgba(196, 196, 196, 0.06)';
        }
        if (checkIfDefaultCandle(newLayout, index)) {
          x.panes[0].sources[0].state.candleStyle.upColor = '#53b987';
          x.panes[0].sources[0].state.candleStyle.downColor = '#eb4d5c';
          x.panes[0].sources[0].state.candleStyle.wickUpColor = '#336854';
          x.panes[0].sources[0].state.candleStyle.wickDownColor = '#7f323f';
          x.panes[0].sources[0].state.candleStyle.borderColor = '#378658';
          x.panes[0].sources[0].state.candleStyle.borderUpColor = '#53b987';
          x.panes[0].sources[0].state.candleStyle.borderDownColor = '#eb4d5c';
          x.panes[0].sources[0].state.statusViewStyle.showExchange = true;
          x.panes[0].sources[0].state.statusViewStyle.showInterval = true;
        }
      });
    }

    return newLayout;
  } catch (error) {
    noticeError(error, {
      event: 'injectThemeToSavedLayout',
      place: 'TradingView/utils',
    });
    return newLayout;
  }
};

export const fixV26StudyDrawing = (layout) => {
  const newLayout = { ...layout };
  try {
    if (!layout.charts || !layout.charts[0]) return layout;
    newLayout.charts[0].panes.forEach((pane) => {
      if (pane.sources[0].type === 'Study') {
        if (!pane.sources[0].state.shortId) {
          pane.sources[0].state.shortId = pane.sources[0].metaInfo.shortId;
        }
      }
    });
    return newLayout;
  } catch (error) {
    noticeError(error, {
      event: 'fixV26StudyDrawing',
      place: 'TradingView/utils',
    });
    return newLayout;
  }
};

export const getStudyParam = (
  tvWidget: IChartingLibraryWidget,
  indicator: IndicatorDataType,
): Parameters<IChartWidgetApi['createStudy']> => {
  let inputData: Record<string, StudyInputValue> = {};
  let overrides: Record<string, any> = {};
  let options: CreateStudyOptions = {};
  const indicatorName = indicator.desc || indicator.name.toLowerCase();
  const lockStudy = false;
  const isOverlay =
    indicatorName.includes('underlay') || indicatorName.includes('overlay');

  if (indicator.inputData) {
    inputData =
      typeof indicator.inputData === 'function'
        ? indicator.inputData(tvWidget)
        : indicator.inputData;
  }
  if (indicator.overrides) overrides = indicator.overrides;
  if (indicator.options) options = indicator.options;

  return [indicatorName, isOverlay, lockStudy, inputData, overrides, options];
};

export const getTVDocument = (parent?: ParentNode) => {
  if (!document) return null;
  // const selector = '#tv_chart_container > iframe';
  const tvElement = (parent || document).querySelector('iframe');
  if (!tvElement) return null;
  // @ts-ignore
  const tvDocument = tvElement.contentDocument;
  return tvDocument;
};

export const getTVWindow = () => {
  if (!document) return null;

  const tvElement = document.querySelector('iframe');
  if (!tvElement) return null;

  const tvWindow = tvElement.contentWindow;
  return tvWindow;
};

export const selectTVElementByQuery = (query: string, parent?: ParentNode) => {
  const tvDoc = getTVDocument(parent);
  if (!tvDoc) return null;
  return tvDoc.querySelector(query);
};

export const getTVResolution = (tvWidgetKey: string): ResolutionString => {
  if (!window?.tvWidget?.[tvWidgetKey]) return '' as ResolutionString;
  return window.tvWidget[tvWidgetKey].activeChart().resolution();
};

export const isTVIntraday = (tvWidgetKey: string): boolean => {
  if (!window?.tvWidget?.[tvWidgetKey]) return false;
  const resolutionInNumber = Number(getTVResolution(tvWidgetKey));
  return !Number.isNaN(resolutionInNumber);
};

export const saveClientTheme = (clientTheme: 'light' | 'dark') => {
  if (!window) return;
  try {
    window.localStorage.setItem('tvTheme', clientTheme);
    window.localStorage.setItem('stradingview.current_theme.name', clientTheme);
    // eslint-disable-next-line no-empty
  } catch (error) {
    noticeError(error, {
      event: 'saveClientTheme',
      place: 'TradingView/utils',
    });
  }
};

export const overrideDesktopDarkMode = (
  tvWidget: IChartingLibraryWidget,
  theme: ThemeName,
) => {
  if (!tvWidget || theme === 'light') return;
  tvWidget.applyOverrides({
    'paneProperties.backgroundType': 'solid',
    'paneProperties.background': '#1F1F1F',
    'paneProperties.vertGridProperties.color': 'rgba(196, 196, 196, 0.06)',
    'paneProperties.horzGridProperties.color': 'rgba(196, 196, 196, 0.06)',
    'scalesProperties.textColor': '#B2B5BE',
    'paneProperties.separatorColor': '#2A2E39',
  });
};

export const overrideSavedDataToDesktopDarkMode = (chartLayout: any) => {
  const overrideLayout = { ...chartLayout };
  const toOverride = [];
  if (chartLayout) {
    overrideLayout.theme = 'dark';

    overrideLayout.layout.charts.forEach((x, index) => {
      const temp = { ...x };

      if (
        x.chartProperties.paneProperties.background.toLowerCase() === '#fff' ||
        x.chartProperties.paneProperties.background.toLowerCase() ===
          '#ffffff' ||
        x.chartProperties.paneProperties.background.toLowerCase() === '#131722'
      ) {
        temp.chartProperties.paneProperties.backgroundType = 'solid';
        temp.chartProperties.paneProperties.background = '#1F1F1F';
        temp.chartProperties.scalesProperties.textColor = '#B2B5BE';
        temp.chartProperties.paneProperties.separatorColor = '#2A2E39';
        temp.chartProperties.paneProperties.vertGridProperties.color =
          'rgba(196, 196, 196, 0.06)';
        temp.chartProperties.paneProperties.horzGridProperties.color =
          'rgba(196, 196, 196, 0.06)';
      }
      if (chartLayout?.savedStateMetaInfo) {
        if (checkIfDefaultCandle(chartLayout?.layout, index)) {
          temp.panes[0].sources[0].state.candleStyle.upColor = '#53b987';
          temp.panes[0].sources[0].state.candleStyle.downColor = '#eb4d5c';
          temp.panes[0].sources[0].state.candleStyle.wickUpColor = '#336854';
          temp.panes[0].sources[0].state.candleStyle.wickDownColor = '#7f323f';
          temp.panes[0].sources[0].state.candleStyle.borderColor = '#378658';
          temp.panes[0].sources[0].state.candleStyle.borderUpColor = '#53b987';
          temp.panes[0].sources[0].state.candleStyle.borderDownColor =
            '#eb4d5c';
          temp.panes[0].sources[0].state.statusViewStyle.showExchange = true;
          temp.panes[0].sources[0].state.statusViewStyle.showInterval = true;
        }
      }

      toOverride.push(temp);
    });
    const newLayout = { ...chartLayout, charts: toOverride };
    return newLayout;
  }

  return overrideLayout;
};

export const getLanguageFromURL = () => {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const optionalArray = <T extends Array<any>>(
  state: boolean,
  value: [...T],
) => (state ? value : []);

export const isSideToolbarOpen = () => {
  const { userSettings } = useChartbitStore.getState();

  if (typeof window === 'undefined') return false;
  const localStorageValue = userSettings['ChartDrawingToolbarWidget.visible'];
  const isDrawingToolVisible =
    localStorageValue === null ? true : localStorageValue === 'true';

  return isDrawingToolVisible;
};

export const isWidgetbarMinimized = () => {
  if (typeof window === 'undefined') return false;

  const widgetBarLayoutSettings = JSON.parse(
    window.localStorage.getItem('stradingview.widgetbar.layout-settings'),
  );
  if (!widgetBarLayoutSettings) return true;

  const isMinimized = !!widgetBarLayoutSettings.settings.minimized;

  return isMinimized;
};

export const getWidgetBarAPI = async (tvWidget: IChartingLibraryWidget) =>
  tvWidget.widgetbar();

export const getDataFromTicker = (ticker: string) => {
  const [exchange, symbolWithStudy] =
    ticker.indexOf(':') > -1 ? ticker.split(':') : [undefined, ticker];

  const [symbol, formula] = symbolWithStudy.split('#');

  return { symbol, exchange, formula };
};

export const getChartSymbol = (
  tvWidget?: IChartingLibraryWidget,
  index?: number,
  widgetKey?: string,
) => {
  const tv = tvWidget || window.tvWidget[widgetKey];
  if (!tv) return '';

  const chart =
    typeof index === 'undefined' ? tv.activeChart() : tv.chart(index);
  if (!chart) return '';

  return chart.symbolExt()?.symbol || getDataFromTicker(chart.symbol()).symbol;
};

export const getUnix = (isoStr: string) => new Date(isoStr).getTime() / 1000;

export const fixDefaultSymbol = (innerContent: Record<string, any>) => {
  const cloned = { ...innerContent };
  if (cloned && cloned?.charts?.length && cloned?.charts?.[0]?.panes?.length) {
    const { panes } = cloned.charts[0];

    panes.forEach((pane) => {
      for (let i = 0; i < pane.sources.length; i += 1) {
        const source = pane.sources[i];
        const { type } = source;
        const isMainSeries = type && type.toUpperCase() === 'MAINSERIES';
        if (!isMainSeries) return;

        // Migrated layout don't have symbol
        // So we set default to IHSG to prevent breaking
        if (!source.state.symbol) {
          source.state.symbol = 'IHSG';
        }
      }
    });
  }
  return cloned;
};

/**
 * Compress String with Zip and base64
 */
export const compressString = async (data: string) => {
  const normalizedData = normalizeToSeriesId(data);
  const layoutZip = new JSZip();
  layoutZip.file('layout.json', normalizedData);

  const base64 = await layoutZip.generateAsync({
    type: 'base64',
    compression: 'DEFLATE',
    compressionOptions: {
      level: 9,
    },
  });
  return base64;
};

export const compressChart = async (
  chartData: ChartData,
  isDesktop: boolean,
) => {
  /** compressedLayoutLimit = 3500000; 3.5MB */
  const uncompressedLayoutLimit = 46725732; // 46.7MB

  const { content, ...parsedContent } = JSON.parse(chartData.content);
  let innerContent = JSON.parse(content);

  const theme: ThemeName = isDesktop ? 'dark' : getClientTheme();
  innerContent = injectThemeToSavedLayout(innerContent, theme, isDesktop);
  const layout = {
    id: chartData.id,
    name: chartData.name,
    resolution: chartData.resolution,
    symbol: chartData.symbol,
    content: {
      ...parsedContent,
      theme,
      content: innerContent,
    },
  };

  const data = JSON.stringify(layout);
  if (data.length > uncompressedLayoutLimit) {
    const message = `Gagal menyimpan, layout "${chartData.name}" telah melewati batas ukuran, harap menghapus beberapa drawing untuk melanjutkan`;
    alertMessage({
      content: message,
      alertType: 'plain-default',
      messageType: 'error',
      hasCloseIcon: false,
    });
    throw Error(message);
  }

  const base64 = await compressString(data);
  return base64;
};

export const decompressChart = async (base64: string) => {
  const layoutZip = await JSZip.loadAsync(base64, { base64: true });
  const layoutString = await layoutZip.file('layout.json').async('string');
  const resultNormalize = normalizeToSeriesId(layoutString);
  const layout = JSON.parse(resultNormalize);
  return layout;
};

/*
 * Temporary Hide Drawing Template Save as Dropdown
 * https://github.com/tradingview/charting_library/issues/7937
 */
export const hideDrawingTemplateSaveMenu = () => {
  const tvDoc = getTVDocument();
  if (!tvDoc?.body) return;

  tvDoc.body.classList.add('chartbit-old');
};

// need to override to change the frame of chart bit when overriding from desktop happens
// can not utilize changeTheme cause will reset
// all of the candle color etc that has been customized by user
export const overrideHtmlClass = ({ dataTheme, source }) => {
  const doc = document.getElementsByTagName('iframe')[0].contentDocument;
  const isiDoc = doc.getElementsByTagName('html')[0].classList;
  if (dataTheme === 'dark') {
    isiDoc.remove('theme-light');
    isiDoc.add('theme-dark');
    // onChangeThemeChartbit('dark');
  }
  if (dataTheme === 'light') {
    isiDoc.remove('theme-dark');
    isiDoc.add('theme-light');
    // onChangeThemeChartbit('dark');
  }
};

export const checkIfDefaultLightMode = (data: any) =>
  data.layout.charts[0].chartProperties.paneProperties.background === '#ffffff';

export const checkThemeToOverride = (data: any, clientTheme?: string) => {
  if (clientTheme !== data.theme) {
    if (
      data.layout.charts[0].chartProperties.paneProperties.background ===
      '#ffffff'
    ) {
      return 'light';
    }
    if (
      data.layout.charts[0].chartProperties.paneProperties.background.toLowerCase() ===
      '#1f1f1f'
    ) {
      return 'dark';
    }
  }
  return data.theme;
};

export const checkIfDefaultCandle = (layout, index = 0) => {
  if (layout) {
    const style =
      layout?.charts?.[index]?.panes?.[0].sources?.[0]?.state?.candleStyle;
    return (
      (style?.upColor === '#089981' && style?.downColor === '#F23645') ||
      (style?.upColor === '#009900' && style?.downColor === '#B80E0C') ||
      (style?.upColor === '#53b987' && style?.downColor === '#eb4d5c')
    );
  }
  return false;
};

export const checkTextColor = (theme, apiTextColor, apiBG) => {
  let overrideText = apiTextColor;
  let overrideBg = apiBG;
  if (theme === 'light' && apiTextColor === '#B2B5BE') {
    overrideText = '#131722';
  }
  if (theme === 'dark' && apiTextColor === '#131722') {
    overrideText = '#B2B5BE';
  }
  if (
    theme === 'dark' &&
    (apiBG.toLowerCase() === '#fff' || apiBG.toLowerCase() === '#ffffff')
  ) {
    overrideBg = '#1f1f1f';
  }
  if (theme === 'light' && apiBG.toLowerCase() === '#1f1f1f') {
    overrideBg = '#fff';
  }
  return { overrideText, overrideBg };
};

export const isTVReady = async (tvWidgetKey: string) =>
  new Promise<boolean>((resolve, reject) => {
    const tv = window?.tvWidget?.[tvWidgetKey];
    if (!tv) reject(Error('TradingView Not Found'));

    tv.onChartReady(() => resolve(true));
  });

// Remove Exchange from symbol
export const normalizeSymbol = (symbol: string) =>
  symbol?.includes(':') ? symbol?.split(':')[1] : symbol;

export const normalizeDrawingSourceSymbol = (data: any) => {
  const formatted = produce(data, (draft) => {
    draft.symbol = normalizeSymbol(draft?.symbol);

    if (draft?.state?.state?.symbol) {
      draft.state.state.symbol = draft?.state?.state?.symbol?.includes(':')
        ? draft?.state?.state?.symbol?.split(':')[1]
        : draft?.state?.state?.symbol;
    }
  });

  return formatted;
};

export const normalizeDrawingGroupSymbol = (data: LineToolsGroupState) => {
  const formatted = produce(data, (draft) => {
    draft.symbol = normalizeSymbol(draft?.symbol);
  });

  return formatted;
};

export const getLocalUserSetting = (prefix: string) => {
  const items = {};

  // Iterate over all keys in localStorage
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    // Check if the key starts with the given prefix
    if (key.startsWith(prefix)) {
      // Get the value associated with the key
      const value = localStorage.getItem(key);

      items[key.replace(`${prefix}.`, '')] = value;
    }
  }
  return items as UserSetting;
};

// merge preexisting user setting from local storage with one from api
export const mergeLocalAndApi = ({
  localUserSetting,
  userSettingFromServer,
}: {
  localUserSetting: UserSetting;
  userSettingFromServer: UserSetting;
}) => {
  const merged = { ...userSettingFromServer, ...localUserSetting };

  try {
    for (const keyFromServer in userSettingFromServer) {
      for (const keyLocal in localUserSetting) {
        if (
          keyFromServer === keyLocal &&
          userSettingFromServer[keyFromServer] !== localUserSetting[keyLocal]
        ) {
          if (Array.isArray(JSON.parse(localUserSetting[keyLocal]))) {
            // put together if array
            const concat = JSON.parse(localUserSetting[keyLocal]).concat(
              JSON.parse(userSettingFromServer[keyFromServer]),
            );
            merged[keyFromServer] = JSON.stringify(concat);
          } else {
            // replace with local if not array
            merged[keyLocal] = localUserSetting[keyLocal];
          }
        }
      }
    }
  } catch (error) {
    return userSettingFromServer;
  }

  for (const i in merged) {
    localStorage.setItem(`stradingview.${i}`, merged[i]);
  }
  return merged;
};

export const normalizeToSeriesId = (data: string) => {
  let cleanData = data;
  /*
    This id is hardcoded id from template config, that founded on
    web and desktop chartbit implementation previouly. Because the id has been saved
    on database, we need a function to replace it with _seriesId as the standard.
    Reference file :
    - Web
      file : src/features/chartbit/config/template.ts
      has D4LkIE value on template Dark, Light,
    - Desktop
      file: chartbit/__components/Modal/TemplateModal/__contants/defaultTemplate.ts
      has D4LkIE value on template Dark, Light,
      has 7a6YCE value on Bandarmology
  */
  const ids = ['D4LkIE', '7a6YCE'];

  ids.forEach((id) => {
    cleanData = cleanData.replace(new RegExp(id, 'g'), '_seriesId');
  });

  return cleanData;
};
